$(document).ready(function () {
  $("header").toggleClass("scrolling", $(window).scrollTop() > 0);

  $(window).on("scroll", function () {
    $("header").toggleClass("scrolling", $(window).scrollTop() > 0);
  });

  $(".home-3 .slider-mat-bang").each(function (index) {
    var $this = $(this);
    $this.addClass("slider-mat-bang-" + index);
    $this.find(".swiper-prev").addClass("btn-prev-" + index);
    $this.find(".swiper-next").addClass("btn-next-" + index);

    let home3swiper = new Swiper(
      ".home-3 .slider-mat-bang-" + index + " .swiper-container",
      {
        slidesPerView: 2.5,
        spaceBetween: 30,
        centeredSlides: true,
        breakpoints: {
          1024.98: {
            slidesPerView: 2,
          },
          768.98: {
            slidesPerView: 1.5,
          },
          576: {
            slidesPerView: 1,
          },
        },
        navigation: {
          prevEl: ".home-3 .btn-prev-" + index,
          nextEl: ".home-3 .btn-next-" + index,
        },
      }
    );
  });

  $(".home-3 .tabs-content").hide();

  $(".ngoai-khu-2 .list-item .item").click(function () {
    var src = $(this).data("src");
    $(".ngoai-khu-2 .list-item .item").removeClass("active");
    $(this).addClass("active");
    $(".ngoai-khu-2 .image img").attr("src", src);
  });

  $(".ngoai-khu-2 .list-item .item").eq(0).trigger("click");

  imageMapJS();

//   scrollFullPage();

$(".homepage main").onepage_scroll({
	sectionContainer: ".section"
})

  // if($(window).width() > 1024) {
  // 	var myFullpage = new fullpage('#fullpage',{
  // 		sectionSelector: '.section',
  // 		onLeave: function () {
  // 			$('header').addClass('scrolling')
  // 		 }
  // 	})
  // }
});

const leftMenuMobileMapping = new MappingListener({
  selector: ".left-menu",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".left-header",
  desktopMethod: "appendTo",
  breakpoint: 1024.98,
}).watch();

const rightMenuMobileMapping = new MappingListener({
  selector: ".right-menu",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".right-header",
  desktopMethod: "prependTo",
  breakpoint: 1024.98,
}).watch();

var nhaMauSlider = new Swiper(".nha-mau .swiper-container", {
  slidesPerView: 3,
  spaceBetween: 30,
  navigation: {
    prevEl: ".nha-mau .swiper-prev",
    nextEl: ".nha-mau .swiper-next",
  },
  observer: true,
  observeParents: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    576: {
      slidesPerView: 1,
    },
  },
});

var nhaMauTienIchSlider = new Swiper(".tien-ich .swiper-container", {
  slidesPerView: 1,
  spaceBetween: 30,
  observer: true,
  observeParents: true,
  thumbs: {
    swiper: nhaMauSlider,
  },
});

function imageMapJS() {
  $("map").imageMapResize();

  $("#imageMap").maphilight({
    fillColor: "2a5caa",
    fillOpacity: 0.7,
    stroke: false,
    groupBy: "group",
  });

  $(".noi-khu-1 .image-map area").each(function () {
    let index = $(this).data("index");
    $(this).qtip({
      content: {
        text: $(
          ".tooltip-container-1 .map-tooltip[data-index='" + index + "']"
        ).clone(),
      },
      position: {
        container: $("div.image-map"),
        my: "bottom center",
        at: "top center",
      },
    });
  });

  $(".mat-bang-1 .image-map area").each(function () {
    let index = $(this).data("index");
    $(this).qtip({
      content: {
        text: $(
          ".tooltip-container-2 .plan-tooltip[data-index='" + index + "']"
        ).clone(),
      },
      position: {
        container: $("div.image-map"),
        my: "bottom center",
        at: "top center",
      },
    });
  });

  $(".mat-bang-2 .image-map area").each(function () {
    let index = $(this).data("index");
    $(this).qtip({
      content: {
        text: $(
          ".tooltip-container-3 .plan-tooltip[data-index='" + index + "']"
        ).clone(),
      },
      position: {
        container: $("div.image-map"),
        my: "center",
        at: "center",
      },
    });
  });

  $(".mat-bang-3 .image-map area").each(function () {
    let index = $(this).data("index");
    $(this).qtip({
      content: {
        text: $(
          ".tooltip-container-4 .plan-tooltip[data-index='" + index + "']"
        ).clone(),
      },
      position: {
        container: $("div.image-map"),
        my: "center",
        at: "center",
      },
    });
  });
}

function scrollFullPage() {
  function ScrollHandler(pageId) {
    var page = $("." + pageId);
    var pageStart = page.offset().top;
    var pageJump = false;

    function scrollToPage() {
      pageJump = true;
      $("html, body").animate(
        {
          scrollTop: pageStart,
        },
        {
          duration: 1000,
          complete: function () {
            pageJump = false;
          },
        }
      );
    }
    window.addEventListener("wheel", function (event) {
      var viewStart = $(window).scrollTop();
      if (!pageJump) {
        var pageHeight = page.height();
        var pageStopPortion = pageHeight / 2;
        var viewHeight = $(window).height();

        var viewEnd = viewStart + viewHeight;
        var pageStartPart = viewEnd - pageStart;
        var pageEndPart = pageStart + pageHeight - viewStart;

        var canJumpDown = pageStartPart >= 0;
        var stopJumpDown = pageStartPart > pageStopPortion;

        var canJumpUp = pageEndPart >= 0;
        var stopJumpUp = pageEndPart > pageStopPortion;

        var scrollingForward = event.deltaY > 0;
        if (
          (scrollingForward && canJumpDown && !stopJumpDown) ||
          (!scrollingForward && canJumpUp && !stopJumpUp)
        ) {
          scrollToPage();
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    });
  }
  new ScrollHandler("home-banner");
  new ScrollHandler("home-1");
  new ScrollHandler("home-2");
  new ScrollHandler("home-3");
  new ScrollHandler("home-4");
  new ScrollHandler("home-5");
  new ScrollHandler("home-6");
  new ScrollHandler("home-7");
  new ScrollHandler("footer");
}
